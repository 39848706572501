import { useTranslation } from 'react-i18next';
import { Whale } from '../common/icons';

const RootLayout = () => {
	const { t } = useTranslation();

	sessionStorage.setItem(
		'wstate',
		new URLSearchParams(window.location.search).get('wstate') as string
	);

	return (
		<>
			<div>
				<div className={'component'}>
					<div className={'innerComponent'}>
						<Whale className={'redirectWhale'} />
						<h1 className={'redirectHeaderText'}>
							{t('loadingPageHeader')}
						</h1>
					</div>
				</div>
			</div>
		</>
	);
};

export default RootLayout;
