import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../common/buttons';
import { CustomInput } from '../../common/inputs';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { useVerifyCodePassword } from '../../../data/mutations/login';
import { ParamContext } from '../../../data/param.provider';

const NewPasswordLayout = () => {
	const { wState } = useContext(ParamContext);

	const { t } = useTranslation();
	const navigate = useCustomNavigate();
	const buttonRef = useRef();

	const [email, setEmail] = useState<string>('');

	const [password, setPassword] = useState<string>('');
	const [isPasswordRequired, setIsPasswordRequired] =
		useState<boolean>(false);

	const [passwordError, setPasswordError] = useState<boolean>(false);

	const { isError, error, verifyCodePassword, isPending } =
		useVerifyCodePassword(navigate);

	useEffect(() => {
		if (isError) {
			setPasswordError(isError);
		}
	}, [isError]);

	useEffect(() => {
		const sessionEmail = sessionStorage.getItem('email');
		if (sessionEmail) {
			setEmail(sessionEmail);
		}
	}, []);

	const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value);
		setPasswordError(false);
		setIsPasswordRequired(e.target.value.length > 0);
	};

	const handlePasswordPaste = (e: React.ClipboardEvent) => {
		const pastePassword = e.clipboardData.getData('text')[0];
		setPassword(pastePassword);
		setPasswordError(false);
		setIsPasswordRequired(pastePassword.length > 0);
	};

	const handlePasswordClear = () => {
		setPassword('');
		setPasswordError(false);
		setIsPasswordRequired(false);
	};

	const handleSubmit = () => {
		verifyCodePassword({
			wState: wState,
			email: email,
			newPassword: password,
			navigate: navigate
		});
	};

	return (
		<>
			<div>
				<div className={'component'}>
					<div className={'innerComponent'}>
						<h1 className={'welcomeText'}>
							{t('recoveryNewPasswordHeader')}
						</h1>
						<CustomInput
							autoComplete={'strabe'}
							isError={passwordError}
							isRequired={isPasswordRequired}
							type={'password'}
							id={'asdzxcqwe'}
							label={t('registrationCreatePassword')}
							value={password}
							onChange={handlePasswordChange}
							onPaste={handlePasswordPaste}
							className={'passwordInput'}
							onClearClick={handlePasswordClear}
							inputPropsRef={buttonRef}
							isPassword={true}
						/>
						{passwordError ? (
							<h1 className={'loginErrorText'}>
								{
									(error as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
								}
							</h1>
						) : (
							''
						)}
						<h1 className={'createPasswordHelp'}>
							{t('registrationCreatePasswordHelp')}
						</h1>
						<CustomButton
							buttonRef={buttonRef}
							isLoading={isPending}
							text={t('registrationStart') as string}
							type={'submit'}
							onClick={handleSubmit}
							className={'finishButton'}
							isDisabled={password.length === 0}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default NewPasswordLayout;
