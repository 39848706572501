import { UpdatedLogo } from '../icons';

const Header = ({ children }: { children: React.ReactNode }) => {
	return (
		<>
			<div className='logoHeader'>
				<UpdatedLogo />
			</div>
			{children}
		</>
	);
};

export default Header;
