import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TWStateInfo } from "../../types/types";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { CustomButton } from "../common/buttons";
import { useRejectMutation, useSendOtpCodeMutation } from "../../data/mutations/payments";
import { ParamContext } from "../../data/param.provider";
import { CustomInput } from "../common/inputs";
import { OTPModal } from "../common/modals";

const WithdrawLayout = () => {
	const { t } = useTranslation();

	const { wState } = useContext(ParamContext);

	const buttonRef = useRef(null);

	const navigate = useCustomNavigate();

	const { reject, isLoading } = useRejectMutation();

	const { sendOtpCode, isPending } = useSendOtpCodeMutation(navigate);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const [sessionWState, setSessionWState] = useState<TWStateInfo | undefined>(
		undefined
	);

	const [otp, setOTP] = useState({ key: "", value: "" });

	const identityTypes = JSON.parse(sessionStorage.getItem("identityTypes") as string);

	useEffect(() => {
		const sessionIdentityTypes = JSON.parse(
			sessionStorage.getItem("identityTypes") as string
		);
		if (sessionIdentityTypes && sessionIdentityTypes?.length === 1) {
			setOTP({
				key: sessionIdentityTypes[0].key,
				value: sessionIdentityTypes[0].value,
			});
		}
		setSessionWState(
			JSON.parse(sessionStorage.getItem("wStateInfo") as string) as TWStateInfo
		);
	}, []);

	const handleReject = async () => {
		reject({ wstate: wState });
	};

	const handleSubmit = async () => {
		sendOtpCode({
			wstate: wState,
			identityType: otp.key,
			navigate: navigate,
		});
	};

	const handleOTPSelect = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleSelectClick = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleOTPChange = (value: { key: string; value: string }) => {
		setOTP({ key: value.key, value: value.value });
	};

	const parseParamValue = (paramValue: string) => {
		if (paramValue === "withdrawCrypto") {
			return t("withdrawCrypto");
		} else if (paramValue === "withdrawBankRu") {
			return t("withdrawBank");
		} else {
			return paramValue;
		}
	};

	return (
		<div className={"component"}>
			<div className={"innerComponent"}>
				<OTPModal
					isModalOpen={isModalOpen}
					handleIsModalOpen={handleOTPSelect}
					handleItemClick={handleOTPChange}
				/>
				<h1 className={"confirmEmailText"}>{t("withdrawIndexHeader")}</h1>
				<div className={"confirmWithdrawContainer"}>
					{sessionWState?.visualParams.map((param) =>
						param.paramValue ? (
							<div key={param.paramId} className="paramField">
								<h1 className="paramKey">{t(param.paramId)}:</h1>
								<h1 className="paramValue">
									{parseParamValue(param.paramValue)}
								</h1>
							</div>
						) : (
							""
						)
					)}
				</div>
				{identityTypes?.length > 1 ? (
					<CustomInput
						isRequired={otp.key === "" ? false : true}
						type={"text"}
						id={"otpSelect"}
						label={
							otp.key === "" ? t("loginSelectTwoFaHeader") : t("otpHeader")
						}
						value={otp.key}
						onClearClick={handleSelectClick}
						className={"otpSelect"}
						isSelectOtpCode={true}
						imgUrl={""}
					/>
				) : (
					""
				)}
				{otp.key === "FIDO2" ? (
					<h1 className={"loginErrorText"}>{t("TWOFASelectWrongOption")}</h1>
				) : (
					""
				)}
				<div className={"confirmButtonContainer"}>
					<CustomButton
						isGrey
						buttonRef={buttonRef}
						isLoading={isLoading}
						text={t("withdrawIndexDenyButton") as string}
						type={"submit"}
						onClick={handleReject}
						className={"confirmButton"}
						isDisabled={false}
					/>
					<CustomButton
						buttonRef={buttonRef}
						isLoading={isPending}
						text={t("withdrawIndexApproveButton") as string}
						type={"submit"}
						onClick={handleSubmit}
						className={"confirmButton"}
						isDisabled={otp.key === "" || otp.key === "FIDO2"}
					/>
				</div>
			</div>
		</div>
	);
};

export default WithdrawLayout;
