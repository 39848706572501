import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePinInput } from "react-pin-input-hook";
import { ParamContext } from "../../../data/param.provider";
import { useWithdrawMutation } from "../../../data/mutations/payments";
import { PinInput } from "../../common/inputs";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { ROUTES } from "../../../types/routes";
import { CustomButton } from "../../common/buttons";

const WithdrawTwoFaLayout = () => {
	const { t } = useTranslation();
	const { wState } = useContext(ParamContext);

	const navigate = useCustomNavigate();

	const { isError, error, withdraw } = useWithdrawMutation();

	const [fakeError, setFakeError] = useState<boolean>(false);

	const [values, setValues] = useState<string[]>(["", "", "", "", "", ""]);
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setFakeError(false);
			setValues(values);
		},
		onComplete: (value: string) => {
			if (value.length === 6) {
				withdraw({
					wstate: wState,
					twoFA: {
						code: value,
						identityType: sessionStorage.getItem("identityType") as string,
					},
				});
			}
		},
		type: "alphanumeric",
		placeholder: "",
	});

	useEffect(() => {
		if (isError) {
			setFakeError(isError);
			setValues(["", "", "", "", "", ""]);
		}
	}, [isError]);

	const handleIdentityType = () => {
		switch (sessionStorage.getItem("identityType")) {
			case "GoogleAuthenticator":
				return "mfCodeDescription";
			case "Sms":
				return "otpCodePhoneDescription";
			case "Email":
				return "otpCodeEmailDescription";
			default:
				return "mfCodeDescription";
		}
	};

	const handleBackClick = () => {
		navigate(ROUTES.WITHDRAW.INDEX);
	};

	return (
		<div className={"component"}>
			<div className={"innerComponent"}>
				<CustomButton
					isBack={true}
					onClick={handleBackClick}
					className={"backButton"}
				/>
				<h1 className={"confirmEmailText"}>{t("withdrawTwoFaHeader")}</h1>
				<h1 className={"confirmCodeText"}>{t(handleIdentityType())}</h1>
				<div className={"codeInputBlock"}>
					<PinInput isError={fakeError} fields={fields} />
					{fakeError ? (
						<h1 className={"errorText"}>
							{
								(error as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
							}
						</h1>
					) : (
						<div className={"errorText"} />
					)}
				</div>
			</div>
		</div>
	);
};

export default WithdrawTwoFaLayout;
