import SumsubWebSdk from '@sumsub/websdk-react';
import { useEffect, useState } from 'react';

const VerificationSumSubLayout = () => {
	const [sumSubAccessToken, setSumSubAccessToken] = useState<string>('');

	useEffect(() => {
		const sumsubInfo = JSON.parse(
			sessionStorage.getItem('sumsubInfo') as string
		);
		if (sumsubInfo) {
			setSumSubAccessToken(sumsubInfo?.widgetConfiguration?.accessToken);
		}
	}, []);

	const expirationHandler = () => {
		console.log('');
	};

	if (sumSubAccessToken === '') {
		return <></>;
	}

	return (
		<div className={'component'}>
			<SumsubWebSdk
					accessToken={sumSubAccessToken}
					expirationHandler={expirationHandler}
				/>
		</div>
	);
};

export default VerificationSumSubLayout;
