import React from 'react';
import { CustomCheckbox } from '../../common/checkboxes';

interface Props {
	checked: boolean[];
	isError: boolean;
	setIsChecked: React.Dispatch<React.SetStateAction<boolean[]>>;
	//eslint-disable-next-line
	checkBoxes: any;
}
const RegistrationCheckboxes = ({
	checked,
	setIsChecked,
	isError,
	checkBoxes
}: Props) => {
	const handleFirstCheckBoxChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		setIsChecked([e.target.checked, checked[1], checked[2]]);
	};

	const handleSecondCheckBoxChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		setIsChecked([checked[0], e.target.checked, checked[2]]);
	};

	const handleThirdCheckBoxChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		setIsChecked([checked[0], checked[1], e.target.checked]);
	};

	return (
		<div className={'checkboxes'}>
			<div className={'firstCheckBox'}>
				<CustomCheckbox
					isError={isError}
					key={'firstCheckBox'}
					checked={checked[0]}
					onChange={handleFirstCheckBoxChange}
				/>
				<h1
					className={'checkBox-h1'}
					dangerouslySetInnerHTML={{
						__html: checkBoxes.checkBoxes[0]?.msgText.replaceAll(
							'/n',
							''
						)
					}}
				>
					{/*<Markdown components={{ a: CustomLink }}>
						{checkBoxes.checkBoxes[0]?.msgText}
					</Markdown>*/}
				</h1>
			</div>
			<div className={'secondCheckBox'}>
				<CustomCheckbox
					isError={isError}
					key={'secondCheckBox'}
					checked={checked[1]}
					onChange={handleSecondCheckBoxChange}
				/>
				<h1
					className={'checkBox-h1'}
					dangerouslySetInnerHTML={{
						__html: checkBoxes.checkBoxes[1]?.msgText?.replaceAll(
							'/n',
							''
						)
					}}
				>
					{/*<Markdown components={{ a: CustomLink }}>
						{checkBoxes.checkBoxes[1]?.msgText}
					</Markdown>*/}
				</h1>
			</div>
			<div className={'secondCheckBox'}>
				<CustomCheckbox
					isError={isError}
					key={'secondCheckBox'}
					checked={checked[2]}
					onChange={handleThirdCheckBoxChange}
				/>
				<h1
					className={'checkBox-h1'}
					dangerouslySetInnerHTML={{
						__html: checkBoxes.checkBoxes[2]?.msgText.replaceAll(
							'/n',
							''
						)
					}}
				>
					{/*<Markdown components={{ a: CustomLink }}>
						{checkBoxes.checkBoxes[0]?.msgText}
					</Markdown>*/}
				</h1>
			</div>
		</div>
	);
};

export default RegistrationCheckboxes;
