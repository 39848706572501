import React, { createContext } from 'react';

export const ParamContext = createContext({
	wState: ''
} as {
	wState: string;
});

const ParamProvider = ({ children }: { children: React.ReactNode }) => {
	const wState = new URLSearchParams(window.location.search).get(
		'wstate'
	) as string;
	/*const wState =
		(sessionStorage.getItem('wstate') as string) != 'null'
			? (sessionStorage.getItem('wstate') as string)
			: (new URLSearchParams(window.location.search).get(
					'wstate'
			  ) as string);*/

	return (
		<ParamContext.Provider value={{ wState }}>
			{children}
		</ParamContext.Provider>
	);
};

export default ParamProvider;
