import { v4 as uuidv4 } from 'uuid';
import { API_ROUTES } from '../types/api.routes';
import { TMappedCheckboxes } from '../types/types';
import { ROUTES } from '../types/routes';
import { customFetch } from '../utils';
import { fetchWStateInfoRegistration } from './common';

export async function fetchRegistrationEula() {
	const wState = new URLSearchParams(window.location.search).get(
		'wstate'
	) as string;

	await fetchWStateInfoRegistration(wState as string);

	const data = await customFetch(
		API_ROUTES.REGISTRATION.REGISTRATION_START.URL,
		{
			method: API_ROUTES.REGISTRATION.REGISTRATION_START.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				wstate: wState
			})
		}
	);
	const jsonData = await data.json();
	if (data.status != 200) {
		throw jsonData;
	}

	const localWStateInfo = JSON.parse(
		sessionStorage.getItem('wStateInfo') as string
	);

	const eulaTextData = await customFetch(
		API_ROUTES.REGISTRATION.GET_EULA.URL +
			'?' +
			new URLSearchParams({
				wstate: wState as string
			}),
		{
			method: API_ROUTES.REGISTRATION.GET_EULA.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				f2x_user_agent: localWStateInfo.f2xUserAgent,
				'Content-Type': 'application/json'
			}
		}
	);

	const jsonEulaTextData = await eulaTextData.json();
	if (eulaTextData.status != 200) {
		if (eulaTextData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		throw jsonEulaTextData;
	}

	sessionStorage.setItem('checkBoxesText', JSON.stringify(jsonEulaTextData));

	return jsonEulaTextData;
}

export async function fetchSubmitClear() {
	const wState = new URLSearchParams(window.location.search).get(
		'wstate'
	) as string;

	const clearData = await customFetch(API_ROUTES.REGISTRATION.CLEAR.URL, {
		method: API_ROUTES.REGISTRATION.CLEAR.METHOD,
		headers: {
			f2x_request_id: uuidv4(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			wstate: wState
		})
	});

	if (clearData.status != 200) {
		if (clearData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		const eulaDataJson = await clearData.json();
		throw eulaDataJson;
	}

	return clearData;
}

export async function fetchSubmitFirstStep(data: {
	email: string;
	checkBoxes: TMappedCheckboxes;
	isEmailVerified: boolean;
}) {
	const wState = new URLSearchParams(window.location.search).get(
		'wstate'
	) as string;

	if (data.isEmailVerified) {
		return;
	}

	const eulaData = await customFetch(API_ROUTES.REGISTRATION.SEND_EULA.URL, {
		method: API_ROUTES.REGISTRATION.SEND_EULA.METHOD,
		headers: {
			f2x_request_id: uuidv4(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			wstate: wState,
			checkBoxesResult: data.checkBoxes
		})
	});

	if (eulaData.status != 200) {
		if (eulaData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		const eulaDataJson = await eulaData.json();
		throw eulaDataJson;
	}

	const codeData = await customFetch(API_ROUTES.REGISTRATION.SEND_CODE.URL, {
		method: API_ROUTES.REGISTRATION.SEND_CODE.METHOD,
		headers: {
			f2x_request_id: uuidv4(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			wstate: wState,
			identityType: 'email',
			identity: data.email
		})
	});

	const codeDataJson = await codeData.json();
	if (codeData.status != 200) {
		if (codeData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		throw codeDataJson;
	}

	sessionStorage.setItem('email', data.email);
	sessionStorage.setItem('inputLength', codeDataJson.length);
	sessionStorage.setItem(
		'resendIn',
		String(Math.floor(codeDataJson.resendIn / 1000))
	);

	return eulaData;
}

export async function fetchAskNewEmailCode() {
	const wState = new URLSearchParams(window.location.search).get(
		'wstate'
	) as string;

	const codeData = await customFetch(API_ROUTES.REGISTRATION.SEND_CODE.URL, {
		method: API_ROUTES.REGISTRATION.SEND_CODE.METHOD,
		headers: {
			f2x_request_id: uuidv4(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			wstate: wState,
			identityType: 'email',
			identity: sessionStorage.getItem('email')
		})
	});

	const codeDataJson = await codeData.json();
	if (codeData.status != 200) {
		if (codeData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		throw codeDataJson;
	}

	sessionStorage.setItem('inputLength', codeDataJson.length);

	sessionStorage.setItem(
		'resendIn',
		String(Math.floor(codeDataJson.resendIn / 1000))
	);

	return codeData;
}

export async function fetchSubmitEmailCode(data: { code: string }) {
	const wState = new URLSearchParams(window.location.search).get(
		'wstate'
	) as string;

	const twoFaData = await customFetch(
		API_ROUTES.REGISTRATION.VERIFY_IDENTITY.URL,
		{
			method: API_ROUTES.REGISTRATION.VERIFY_IDENTITY.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				wstate: wState,
				identityType: 'email',
				identity: sessionStorage.getItem('email'),
				code: data.code
			})
		}
	);

	const twoFaDataJson = await twoFaData.json();
	if (twoFaData.status != 200) {
		if (twoFaData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		throw twoFaDataJson;
	}

	return twoFaDataJson;
}

export async function fetchRegistrationCountries(dictionaryId: string) {
	const countriesData = await customFetch(
		API_ROUTES.REGISTRATION.DICTIONARY_BY_ID.URL.replace(
			'{dictId}',
			dictionaryId
		) +
			'?' +
			new URLSearchParams({
				state: new URLSearchParams(window.location.search).get(
					'wstate'
				) as string
			}),
		{
			method: API_ROUTES.REGISTRATION.DICTIONARY_BY_ID.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			}
		}
	);
	const jsonCountriesData = await countriesData.json();
	if (countriesData.status != 200) {
		if (countriesData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		throw jsonCountriesData;
	}
	return jsonCountriesData;
}

export async function fetchSubmitPhoneVerifyCode(data: {
	phone: string;
	isPhoneVerified: boolean;
}) {
	const wState = new URLSearchParams(window.location.search).get(
		'wstate'
	) as string;

	if (data.isPhoneVerified) {
		const locale =
			window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
		window.location.replace(
			ROUTES.LOGIN.INDEX.replace(':locale', locale) + `?wstate=${wState}`
		);
	}

	const codeData = await customFetch(API_ROUTES.REGISTRATION.SEND_CODE.URL, {
		method: API_ROUTES.REGISTRATION.SEND_CODE.METHOD,
		headers: {
			f2x_request_id: uuidv4(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			wstate: wState,
			identityType: 'phone',
			identity: data.phone
		})
	});

	const codeDataJson = await codeData.json();
	if (codeData.status != 200) {
		if (codeData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		throw codeDataJson;
	}

	sessionStorage.setItem('inputLength', codeDataJson.length);
	sessionStorage.setItem(
		'resendIn',
		String(Math.floor(codeDataJson.resendIn / 1000))
	);

	return codeData;
}

export async function fetchAskNewPhoneCode() {
	const wState = new URLSearchParams(window.location.search).get(
		'wstate'
	) as string;

	const codeData = await customFetch(API_ROUTES.REGISTRATION.SEND_CODE.URL, {
		method: API_ROUTES.REGISTRATION.SEND_CODE.METHOD,
		headers: {
			f2x_request_id: uuidv4(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			wstate: wState,
			identityType: 'phone',
			identity: sessionStorage.getItem('phone')?.replaceAll(' ', '')
		})
	});

	const codeDataJson = await codeData.json();
	if (codeData.status != 200) {
		if (codeData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		throw codeDataJson;
	}

	sessionStorage.setItem('inputLength', codeDataJson.length);
	sessionStorage.setItem(
		'resendIn',
		String(Math.floor(codeDataJson.resendIn / 1000))
	);

	return codeData;
}

export async function fetchSubmitPhoneCode(data: { code: string }) {
	const wState = new URLSearchParams(window.location.search).get(
		'wstate'
	) as string;

	const codeData = await customFetch(
		API_ROUTES.REGISTRATION.VERIFY_CODE.URL,
		{
			method: API_ROUTES.REGISTRATION.VERIFY_CODE.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				wstate: wState,
				identityType: 'phone',
				identity: sessionStorage.getItem('phone')?.replaceAll(' ', ''),
				code: data.code
			})
		}
	);

	const codeDataJson = await codeData.json();
	if (codeData.status != 200) {
		if (codeData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		throw codeDataJson;
	}

	return codeDataJson;
}

export async function fetchSubmitLastStep(data: {
	name: string;
	password: string;
	referal: string;
}) {
	const lastStepData = await customFetch(
		API_ROUTES.REGISTRATION.CREATE_ACCOUNT.URL,
		{
			method: API_ROUTES.REGISTRATION.CREATE_ACCOUNT.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				state: localStorage.getItem('state'),
				password: data.password,
				name: data.name,
				referal: data.referal
			})
		}
	);

	if (lastStepData.status != 200) {
		if (lastStepData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		const lastStepDataJson = await lastStepData.json();
		throw lastStepDataJson;
	}

	return lastStepData;
}
