import { ROUTES } from "../types/routes";

export async function customFetch(
	input: RequestInfo | URL,
	init?: RequestInit | undefined
): Promise<Response> {
	const locale = window.location.pathname.split("/")[1] != "en" ? "ru" : "en";
	const customHeaders = {
		"Accept-Language": locale.toLocaleLowerCase() + "-" + locale.toUpperCase(),
		...init?.headers,
	};
	if (init?.headers) {
		init.headers = customHeaders;
	}

	return await fetch(input, init);
}

export function mapRegistrationPage(page: string): string | undefined {
	const registrationPages: {
		[key: string]: string;
	} = {
		registrationEulaEmailIdentityPageId: ROUTES.REGISTRATION.INDEX,
		registrationPhonePageId: ROUTES.REGISTRATION.PHONE,
		registrationProfilePageId: ROUTES.VERIFICATION.INDEX,
		registrationSamsubPageId: ROUTES.VERIFICATION.SUMSUB,
		finishPageId: ROUTES.REDIRECT.INDEX,
	};
	if (registrationPages[page]) {
		return registrationPages[page];
	}
}
