import { Helmet } from "react-helmet";
import { TITLES } from "../types/titles";
import RegistrationPersonalLayout from "../components/registration/personal";
import { QueryClient } from "@tanstack/react-query";
import { fetchRegistrationEula } from "../api/registration";

export const registrationPersonalLoader = () => {
	return new QueryClient().fetchQuery({
		queryKey: ["registrationEula"],
		queryFn: () => fetchRegistrationEula(),
		staleTime: Infinity,
	});
};

const RegistrationPersonal = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.REGISTRATION.PERSONAL}</title>
			</Helmet>
			<RegistrationPersonalLayout />
		</>
	);
};

export default RegistrationPersonal;
