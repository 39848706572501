import { API_ROUTES } from '../types/api.routes';
import { ROUTES } from '../types/routes';
import { TWithdrawBody } from '../types/types';
import { customFetch } from '../utils';
import { v4 as uuidv4 } from 'uuid';

export async function fetchWStateInfo(wState: string) {
	const wStateInfoData = await customFetch(
		API_ROUTES.PAYMENTS.WSTATE_INFO.URL.replace('{wstate}', wState),
		{
			method: API_ROUTES.PAYMENTS.WSTATE_INFO.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			}
		}
	);

	const jsonWStateInfoData = await wStateInfoData.json();
	if (wStateInfoData.status != 200) {
		if (wStateInfoData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		throw jsonWStateInfoData;
	}
	sessionStorage.setItem('wStateInfo', JSON.stringify(jsonWStateInfoData));
	sessionStorage.setItem(
		'identityTypes',
		JSON.stringify(
			jsonWStateInfoData.twoFA.identityTypes.map(
				//eslint-disable-next-line
				(identityType: any) =>
					(identityType = {
						key: identityType.name,
						value: identityType.name
					})
			)
		)
	);

	return jsonWStateInfoData;
}

export async function fetchWithdraw(data: TWithdrawBody) {
	const withdrawData = await customFetch(API_ROUTES.PAYMENTS.WITHDRAW.URL, {
		method: API_ROUTES.PAYMENTS.WITHDRAW.METHOD,
		headers: {
			f2x_request_id: uuidv4(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			wstate: data.wstate,
			twoFA: {
				code: data.twoFA.code,
				identityType: data.twoFA.identityType
			}
		})
	});

	const jsonWithdrawData = await withdrawData.json();
	if (withdrawData.status != 200) {
		if (withdrawData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		throw jsonWithdrawData;
	}

	return jsonWithdrawData;
}

export async function fetchRejectOperation(data: { wstate: string }) {
	const rejectData = await customFetch(API_ROUTES.PAYMENTS.REJECT.URL, {
		method: API_ROUTES.PAYMENTS.REJECT.METHOD,
		headers: {
			f2x_request_id: uuidv4(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			wstate: data.wstate
		})
	});

	const jsonRejectData = await rejectData.json();
	if (rejectData.status != 200) {
		if (rejectData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		throw jsonRejectData;
	}

	return jsonRejectData;
}

export async function fetchSendOtpCode(data: {
	wstate: string;
	identityType: string;
	navigate: (path: string) => void;
}) {
	sessionStorage.setItem('identityType', data.identityType);
	if (data.identityType === 'GoogleAuthenticator') {
		data.navigate(ROUTES.WITHDRAW.TWOFA);
		return;
	}

	const sendOtpCodeData = await customFetch(
		API_ROUTES.PAYMENTS.SEND_CODE.URL,
		{
			method: API_ROUTES.PAYMENTS.SEND_CODE.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				wstate: data.wstate,
				identityType: data.identityType
			})
		}
	);

	const jsonSendOtpCodeData = await sendOtpCodeData.json();
	if (sendOtpCodeData.status != 200) {
		if (sendOtpCodeData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		throw jsonSendOtpCodeData;
	}

	return jsonSendOtpCodeData;
}
