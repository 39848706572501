import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { CustomButton } from '../../common/buttons';
import { useContext, useRef, useState } from 'react';
import { OTPModal } from '../../common/modals';
import { CustomInput } from '../../common/inputs';
import { useSendOtpCodeMutation } from '../../../data/mutations/login';
import { ParamContext } from '../../../data/param.provider';

const LoginSelectTwoFaLayout = () => {
	const { t } = useTranslation();

	const { wState } = useContext(ParamContext);

	const buttonRef = useRef(null);

	const navigate = useCustomNavigate();

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const { sendOtpCode, isError, error, isPending } =
		useSendOtpCodeMutation(navigate);

	const [otp, setOTP] = useState({ key: '', value: '' });

	const handleBackClick = () => {
		navigate(ROUTES.LOGIN.INDEX);
	};

	const handleOTPSelect = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleSelectClick = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleOTPChange = (value: { key: string; value: string }) => {
		setOTP({ key: value.key, value: value.value });
	};

	const handleLogin = () => {
		if (otp.key === 'GoogleAuthenticator') {
			navigate(ROUTES.LOGIN.TWO_FA);
		} else {
			sendOtpCode({
				wState: wState,
				identityType: otp.key,
				f2xUserAgent: JSON.parse(
					sessionStorage.getItem('wStateInfo') as string
				)?.f2xUserAgent,
				navigate: navigate
			});
		}
	};

	return (
		<>
			<div>
				<div className={'component'}>
					<div className={'innerComponent'}>
						<OTPModal
							isModalOpen={isModalOpen}
							handleIsModalOpen={handleOTPSelect}
							handleItemClick={handleOTPChange}
						/>
						<CustomButton
							isBack={true}
							onClick={handleBackClick}
							className={'backButton'}
						/>
						<h1 className={'welcomeText'}>
							{t('loginSelectTwoFaHeader')}
						</h1>
						<h1 className={'confirmCodeText'}>
							{t('loginSelectTwoFaMain')}
						</h1>
						<CustomInput
							isDisabled={false}
							isRequired={otp.key === '' ? false : true}
							type={'text'}
							id={'otpSelect'}
							label={
								otp.key === ''
									? t('loginSelectTwoFaHeader')
									: t('otpHeader')
							}
							value={otp.key}
							onClearClick={handleSelectClick}
							className={'otpSelect'}
							isSelectOtpCode={true}
							imgUrl={''}
						/>
						{isError ? (
							<h1 className={'loginErrorText'}>
								{
									(error as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
								}
							</h1>
						) : (
							''
						)}
						{otp.key === 'FIDO2' ? (
							<h1 className={'loginErrorText'}>
								{t('TWOFASelectWrongOption')}
							</h1>
						) : (
							''
						)}
						<CustomButton
							buttonRef={buttonRef}
							isLoading={isPending}
							text={t('loginText') as string}
							type={'submit'}
							onClick={handleLogin}
							className={'loginButton'}
							isDisabled={otp.key === '' || otp.key === 'FIDO2'}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default LoginSelectTwoFaLayout;
