import { useMutation } from '@tanstack/react-query';
import { TWithdrawBody } from '../../types/types';
import {
	fetchRejectOperation,
	fetchSendOtpCode,
	fetchWithdraw
} from '../../api/payments';
import { ROUTES } from '../../types/routes';

export function useWithdrawMutation() {
	const {
		mutateAsync: withdraw,
		isError,
		error: error
	} = useMutation({
		mutationKey: ['withdraw'],
		mutationFn: (data: TWithdrawBody) => fetchWithdraw(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data.nextPageId === 'finishPageId') {
				sessionStorage.setItem('operationStatus', 'success');
				window.location.replace(
					`/${
						window.location.pathname.split('/')[1]
					}/widget/payments/finish`
				);
			} else if (data.paymentId) {
				sessionStorage.setItem('operationStatus', 'success');
				window.location.replace(
					`/${
						window.location.pathname.split('/')[1]
					}/widget/payments/finish`
				);
			}
		}
	});
	return {
		withdraw,
		error,
		isError
	};
}

export function useRejectMutation() {
	const { mutateAsync: reject, isPending: isLoading } = useMutation({
		mutationKey: ['rejectOperation'],
		mutationFn: (data: { wstate: string }) => fetchRejectOperation(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data.nextPageId === 'finishPageId') {
				sessionStorage.setItem('operationStatus', 'failure');
				window.location.replace(
					`/${
						window.location.pathname.split('/')[1]
					}/widget/payments/finish`
				);
			}
		}
	});
	return {
		reject,
		isLoading
	};
}

export function useSendOtpCodeMutation(navigate: (path: string) => void) {
	const { mutateAsync: sendOtpCode, isPending } = useMutation({
		mutationKey: ['sendOtpCodeWithdraw'],
		mutationFn: (data: {
			wstate: string;
			identityType: string;
			navigate: (path: string) => void;
		}) => fetchSendOtpCode(data),
		onSuccess: async () => {
			navigate(ROUTES.WITHDRAW.TWOFA);
		}
	});
	return {
		sendOtpCode,
		isPending
	};
}
