import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ParamContext } from "../../../data/param.provider";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { useImpersonateMutation } from "../../../data/mutations/login";
import { CustomButton } from "../../common/buttons";
import { TEulaResponse, TWStateInfo } from "../../../types/types";
import { ROUTES } from "../../../types/routes";

interface Props {
	eula: TEulaResponse;
}
const ImpersonateLayout = ({ eula }: Props) => {
	const { t } = useTranslation();
	const { wState } = useContext(ParamContext);

	const [sessionWState, setSessionWState] = useState<TWStateInfo | undefined>(
		undefined
	);

	const buttonRef = useRef(null);

	const navigate = useCustomNavigate();

	const { isPending, impersonate } = useImpersonateMutation(navigate);

	useEffect(() => {
		setSessionWState(
			JSON.parse(sessionStorage.getItem("wStateInfo") as string) as TWStateInfo
		);
	}, []);

	const handleSubmit = async () => {
		await impersonate({
			wstate: wState,
			checkBoxesResult: eula.checkBoxes.map(
				//eslint-disable-next-line
				(checkBox: any) => {
					return (checkBox = {
						checkBoxId: checkBox.checkBoxId,
						isSelected: true,
					});
				}
			),
			f2xUserAgent: sessionWState?.f2xUserAgent as string,
		});
	};

	return (
		<div className={"component"}>
			<div className={"innerComponent"}>
				<h1 className={"confirmEmailText"}>{t("loginImpersonateHeader")}</h1>
				{eula.checkBoxes.map((checkbox) => (
					<h1
						className={"confirmCodeText"}
						dangerouslySetInnerHTML={{
							__html: checkbox.msgText as string,
						}}
					></h1>
				))}

				<div className={"confirmButtonContainer"}>
					<CustomButton
						isGrey
						buttonRef={buttonRef}
						isLoading={isPending}
						text={t("loginImpersonateDontAllowButton") as string}
						type={"submit"}
						onClick={() => navigate(ROUTES.LOGIN.INDEX)}
						className={"confirmButton"}
						isDisabled={false}
					/>
					<CustomButton
						buttonRef={buttonRef}
						isLoading={isPending}
						text={t("loginImpersonateAllowButton") as string}
						type={"submit"}
						onClick={handleSubmit}
						className={"confirmButton"}
						isDisabled={false}
					/>
				</div>
			</div>
		</div>
	);
};

export default ImpersonateLayout;
