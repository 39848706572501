export const TITLES = {
	REGISTRATION: {
		INDEX: 'Registration',
		PERSONAL: 'Personal Account | Registration',
		LEGAL: 'Legal Account | Registration',
		EMAIL: 'Email Code | Registration',
		PHONE: 'Phone | Registration',
		PHONE_CODE: 'Phone Code | Registration',
		SUMMARY: 'Summary | Registration',
		DATA: 'Data | Registration',
		MAINTAIN: 'Maintain | Registration'
	},
	LOGIN: {
		INDEX: 'Login',
		TWOFA: 'TwoFA | Login',
		SELECT_TWOFA: 'Select TwoFa | Login',
		OTP: 'OTP | Login',
		SENT: 'Sent | Login',
		IMPERSONATE: 'Impersonate | Login',
		RECOVERY: 'Recovery | Login',
		CONFIRM_EMAIL: 'Confirm Code | Login',
		NEW_PASSWORD: 'New Password | Login'
	},
	VERIFICATION: {
		INDEX: 'Verification',
		QUESTIONARY: 'Questionary | Verification',
		SUMSUB: 'SumSub | Verification'
	},
	WITHDRAW: {
		INDEX: 'Withdraw',
		TWOFA: 'TwoFa | Withdraw',
		SUMMARY: 'Summary | Withdraw'
	},
	ERROR: {
		INDEX: 'Error'
	},
	REDIRECT: {
		INDEX: 'Redirect'
	},
	LOADING: {
		INDEX: 'Loading'
	}
};
